//
// Skin: Persona
// Mode: Light
//

$primary: #2d73ca;

$warning: orangered;

$navbar-brand-size: 4.5rem;

